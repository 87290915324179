import ApiService from '@/api/http/ApiService';
import axios from 'axios';

export class InventoryApiService extends ApiService<any> {
  constructor() {
    super('inventory');
  }

  public storeInventory(param: any) {
    return axios.post(`${this.getBaseUrl()}/`, param);
  }

  public getInventory(data: { venue: string; params: any }) {
    return axios.get(`${this.getBaseUrl()}/venue/${data.venue}`, {
      params: data.params,
    });
  }

  public updateInventory(data: { venue: string; id: string, inventory: any }) {
    return axios.patch(`${this.getBaseUrl()}/venue/${data.venue}/inventory/${data.id}`, data.inventory);
  }
}
