import { configure, extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import {
  alpha_num,
  confirmed,
  email,
  integer,
  max,
  max_value,
  min,
  min_value,
  required,
} from 'vee-validate/dist/rules';
import Vue from 'vue';
import moment, { Moment } from 'moment';
import { arraysEqual, getListOfEmailsRegexp, getListOfIntegersRegexp, getUrlRegexp } from '@/util/helper';
import AuthApiService from '@/api/http/AuthApiService';
import i18n from '@/i18n';
import VenueApiService from '@/api/http/VenueApiService';
import { PromoCodeApiService } from '@/api/http/PromoCodeApiService';
import VenueLabelApiService from '@/api/http/VenueLabelApiService';
import { getTimeRegexp } from '../util/helper';
import { CompanyApiService } from '@/api/http/CompanyApiService';

configure({
  defaultMessage: (field: string, values: any): string => {
    values._field_ = field;
    return i18n.t(`validations.${values._rule_}`, values) as string;
  },
});

extend('required', required);
extend('email', email);
extend('integer', integer);
extend('confirmed', confirmed);
extend('min', min);
extend('max', max);
extend('max_value', max_value);
extend('min_value', min_value);
extend('alpha_num', alpha_num);
extend('unique', {
  validate(value, args) {
    if (value) {
      return i18n.t('validations.uniqueFile') as string;
    }

    return false;
  },
});

extend('unique_email', {
  validate(value, args) {
    if (value) {
      const api: AuthApiService = new AuthApiService();

      return (
        api
          // @ts-ignore
          .validateEmail(value, args[0] ? { id: args[0] } : {})
          .then(() => {
            return false;
          })
          .catch(() => true)
      );
    }

    return false;
  },
});

extend('uniq_company_code', {
  validate(value, args: any) {
    if (!value) {
      return false;
    }

    const companyCode = value;
    const except = args?.length > 0 ? args[0] : null;
    const api = new CompanyApiService();

    return api
      .checkUnique({ companyCode, except })
      .then((res) => {
        return res.data.unique;
      })
      .catch(() => true);
  },
});

extend('promo', {
  validate(value, args) {
    if (value) {
      const api: PromoCodeApiService = new PromoCodeApiService();
    }

    return false;
  },
});

extend('readable_id', {
  validate(value, args) {
    if (value) {
      const api: VenueApiService = new VenueApiService();
      return (
        api
          // @ts-ignore
          .validateReadableId(value, args[0] ? { except: args[0] } : {})
          .then(() => {
            return true;
          })
          .catch(() => false)
      );
    }

    return false;
  },
});

extend('venue_label', {
  validate(value, args) {
    if (value) {
      const api: VenueLabelApiService = new VenueLabelApiService();

      return (
        api
          // @ts-ignore
          .validateVenueLabel(value, args[0] ? { except: args[0] } : {})
          .then(() => {
            return true;
          })
          .catch(() => false)
      );
    }

    return false;
  },
});

extend('password', {
  validate(value, args) {
    return /^(?=.*[A-Z])(?=.*[\^!§$%&\/(){}\[\]=\\?€@#*+~.,;:\-_<>°|´`0-9])[a-zA-Z0-9^!§$%&\/(){}\[\]=\\?€@#*+~.,;:\-_<>°|´`]*$/.test(
      value,
    );
  },
});

extend('time_before', {
  validate(value, { target }: Record<string, any>) {
    if (!target) {
      return true;
    }

    const from: Moment = moment(value, 'HH:mm');
    const to: Moment = moment(target, 'HH:mm');
    return !from.isAfter(to);
  },
  message: (field: string, placeholders: any) => {
    return i18n.t('validations.time_before', { field, target: placeholders!.target }) as string;
  },
  params: ['target'],
});

extend('date_before', {
  validate(value, { target }: Record<string, any>) {
    if (!target) {
      return true;
    }

    const from: Moment = moment(value);
    const to: Moment = moment(target);
    return !from.isAfter(to);
  },
  message: (field: string, placeholders: any) => {
    return i18n.t('validations.date_before', { field, target: placeholders!.target }) as string;
  },
  params: ['target'],
});

extend('smaller_than', {
  validate(value, { target }: Record<string, any>) {
    if (!target) {
      return true;
    }
    const parsedVal: number = parseFloat(value);
    const targetVal: number = parseFloat(target);

    return parsedVal <= targetVal;
  },
  message: (field: string, placeholders: any) => {
    return i18n.t('validations.smaller_than', { field, target: placeholders!.target }) as string;
  },
  params: ['target'],
});

extend('greater_than', {
  validate(value, { target }: Record<string, any>) {
    if (!target) {
      return true;
    }
    const parsedVal: number = parseFloat(value);
    const targetVal: number = parseFloat(target);

    return parsedVal >= targetVal;
  },
  message: (field: string, placeholders: any) => {
    return i18n.t('validations.greater_than', { field, target: placeholders!.target }) as string;
  },
  params: ['target'],
});

extend('greater_eq_than', {
  validate(value, { target }: Record<string, any>) {
    if (!target) {
      return true;
    }
    const parsedVal: number = parseFloat(value);
    const targetVal: number = parseFloat(target);

    return parsedVal >= targetVal;
  },
  message: (field: string, placeholders: any) => {
    return i18n.t('validations.greater_eq_than', { field, target: placeholders!.target }) as string;
  },
  params: ['target'],
});

extend('unique_dependency', {
  validate(value, { target1, target2, target3, target4, target5, target6, target7, target8 }: any) {
    const targets: string[][] = [target1, target2, target3, target4, target5, target6, target7, target8];

    for (const target of targets) {
      if (target && arraysEqual(value, target)) {
        return false;
      }
    }

    return true;
  },
  message: (field: string) => {
    return i18n.t('validations.unique_dependency', { field }) as string;
  },
  params: ['target1', 'target2', 'target3', 'target4', 'target5', 'target6', 'target7', 'target8'],
});

extend('uppercase', {
  validate(value: string) {
    return value && value === value.toUpperCase();
  },
  message: () => i18n.t('validations.uppercase') as string,
});

extend('vat_number', {
  validate(value: string, args: Record<string, any>) {
    if (value && value.length >= 5) {
      return true;
      /*const api: VenueApiService = new VenueApiService();
      return (
        api
          // @ts-ignore
          .validateVatNumber(value, !!args[0])
          .then(() => true)
          .catch(() => false)
      );*/
    }

    return false;
  },
  message: () => i18n.t('validations.vatNumber.error') as string,
});

extend('vat_number_germany', {
  validate(value: string, args: Record<string, any>) {
    if (value && value.length >= 5) {
      return true;
      /*const api: VenueApiService = new VenueApiService();
      return (
        api
          // @ts-ignore
          .validateVatNumberGermany(value, !!args[0])
          .then(() => true)
          .catch(() => false)
      );*/
    }

    return false;
  },
  message: () => i18n.t('validations.vatNumberGermany.error') as string,
});

extend('price', {
  validate(value: string) {
    return /^(-?\d)\d*([.,]\d{0,2})?$/.test(value);
  },
  message: () => i18n.t('validations.price') as string,
});

extend('decimal', {
  validate(value: string) {
    return /^(?=.?\d)\d*([.]\d{0,2})?$/.test(value);
  },
  message: () => i18n.t('validations.nutritional_value') as string,
});

extend('numbers_with_comma_or_dot', {
  validate(value: string) {
    return /\d+(?:[.,]\d+)*$/.test(value);
  },
  message: (field: string) => i18n.t('validations.numbers_with_comma_or_dot', { field }) as string,
});
extend('list_of_emails', {
  validate(value: string) {
    if (typeof value === 'string') {
      return getListOfEmailsRegexp().test(value);
    }

    return true;
  },
  message: () => i18n.t('validations.list_of_emails') as string,
});

extend('list_of_integer', {
  validate(value: string) {
    if (typeof value === 'string') {
      return getListOfIntegersRegexp().test(value);
    }

    return true;
  },
  message: () => i18n.t('validations.list_of_integer') as string,
});

extend('quantity_number', {
  validate(value: string) {
    if (typeof value === 'string') {
      return /^(?:\d{1,5}|100000)$/.test(value);
    }

    return true;
  },
  message: () => i18n.t('validations.quantity_number') as string,
});

extend('quantity_number_less_100k', {
  validate(value, { target }: Record<string, any>) {
    if (!target) {
      return true;
    }

    const diff: number = target - value;
    return diff < 100 * 10000 && diff > 0;
  },
  message: (field: string, placeholders: any) => {
    return i18n.t('validations.quantity_number_less_100k', { field, target: placeholders!.target }) as string;
  },
  params: ['target'],
});

extend('quantity_number_less_10k', {
  validate(value, { target }: Record<string, any>) {
    if (!target) {
      return true;
    }
    const diff: number = target - value;
    return diff < 10 * 1000 && diff > 0;
  },
  message: (field: string, placeholders: any) => {
    return i18n.t('validations.quantity_number_less_10k', { field, target: placeholders!.target }) as string;
  },
  params: ['target'],
});

extend('eod_email_enable', {
  validate(value: boolean) {
    return !value;
  },
  message: () => i18n.t('validations.eod_email_enable') as string,
});

extend('time', {
  validate(value, args) {
    return getTimeRegexp().test(value);
  },
  message: () => i18n.t('validations.time') as string,
});

extend('url', {
  validate(value, args) {
    return getUrlRegexp().test(value);
  },
  message: () => i18n.t('validations.url') as string,
});
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
